.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
    &.wrapper-full-page {
        min-height: 100vh;
        height: auto;
    }
}

.sidebar,
.off-canvas-sidebar {
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 260px;
    left: 0;
    z-index: 9999;
    box-shadow: $sidebar-box-shadow;
    .sidebar-wrapper {
        position: relative;
        height: calc(100vh - 75px);
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;
        .dropdown .dropdown-backdrop {
            display: none !important;
        }
        .navbar-form {
            border: none;
        }
        >.nav,
        .user .info {
            [data-toggle="collapse"]~div>ul>li>a {
                span {
                    display: inline-block;
                    @extend .animation-transition-general;
                }
                .sidebar-normal {
                    margin: 0;
                    position: relative;
                    transform: translateX(0px);
                    opacity: 1;
                    white-space: nowrap;
                    display: block;
                    line-height: 23px;
                    z-index: 1;
                }
                .sidebar-mini-icon {
                    text-transform: uppercase;
                    width: 34px;
                    margin-right: 10px;
                    margin-left: 0px;
                    font-size: 12px;
                    text-align: center;
                    line-height: 25px;
                    position: relative;
                    float: left;
                    z-index: 1;
                    display: inherit;
                    line-height: 24px;
                    color: $opacity-5;
                }
                i {
                    font-size: 17px;
                    line-height: 20px;
                    width: 26px;
                }
            }
        }
        [data-toggle="collapse"]~div>ul>li.active>a {
            .sidebar-mini-icon {
                color: $primary-color;
            }
        }
    }
    .navbar-minimize {
        position: absolute;
        right: 18px;
        top: 2px;
        opacity: 1;
        .btn {
            &,
            &:active,
            &:focus,
            &:hover {
                background-color: transparent !important;
            }
        }
        @extend .animation-transition-general;
    }
    .logo-tim {
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;
        img {
            width: 60px;
            height: 60px;
        }
    }
    .nav {
        margin-top: 20px;
        display: block;
        .caret {
            top: 14px;
            position: absolute;
            right: 10px;
        }
        li {
            >a+div .nav li>a {
                margin-top: 7px;
            }
            >a {
                margin: 10px 15px 0;
                border-radius: $btn-round-radius;
                color: $white-color;
                display: block;
                text-decoration: none;
                position: relative;
                text-transform: uppercase;
                cursor: pointer;
                font-size: $font-size-mini;
                padding: 10px 8px;
                line-height: $line-height-nav-link;
            }
            &:first-child>a {
                margin: 0 15px;
            }
            &:hover:not(.active)>a,
            &:focus:not(.active)>a {
                background-color: $opacity-1;
            }
            &:hover:not(.active)>a i,
            &:focus:not(.active)>a i {
                color: $white-color;
            }
            &.active>a:not([data-toggle="collapse"]) {
                background-color: #3CBFF0;
                box-shadow: $box-shadow;
            }
            &.active>a[data-toggle="collapse"] {
                background-color: $opacity-1;
                box-shadow: none;
                color: $white-color;
                i {
                    color: $white-color;
                }
                &+div .nav .active a {
                    background-color: transparent;
                    box-shadow: none;
                    &:after {
                        content: "";
                        position: absolute;
                        background-color: #3CBFF0;
                        border-radius: 30px;
                        box-shadow: $box-shadow;
                        color: $primary-color;
                        height: 44px;
                        width: calc(100% - 5px);
                        top: 0;
                        left: 2px;
                        z-index: 0;
                    }
                }
            }
        }
        p {
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            @extend .animation-transition-general;
        }
        i {
            font-size: 20px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: $opacity-5;
            position: relative;
        }
    }
    .sidebar-background {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        &:after {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #ffffff;
            opacity: 1;
        }
    }
    .logo {
        position: relative;
        padding: $padding-base-vertical $padding-base-horizontal;
        z-index: 4;
        a.logo-mini,
        a.logo-normal {
            @extend .animation-transition-general;
        }
        a.logo-mini {
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 10px;
            margin-right: 12px;
        }
        a.logo-normal {
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;
        }
        p {
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        .simple-text {
            text-transform: uppercase;
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: $white-color;
            text-decoration: none;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
        }
    }
    .logo-tim {
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;
        img {
            width: 60px;
            height: 60px;
        }
    }
    &:before,
    &:after {
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &:after {
        @include icon-gradient($default-color);
        z-index: 3;
    }
    &[data-color="blue"] {
        @include sidebar-color($info-color);
    }
    &[data-color="green"] {
        @include sidebar-color($success-color);
    }
    &[data-color="orange"] {
        @include sidebar-color($orange-color);
    }
    &[data-color="red"] {
        @include sidebar-color($danger-color);
    }
    &[data-color="yellow"] {
        @include sidebar-color($warning-color);
    }
    .user {
        padding-bottom: 20px;
        margin: 20px auto 0;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;
        }
        .photo {
            width: 34px;
            height: 34px;
            overflow: hidden;
            float: left;
            z-index: 5;
            margin-right: 10px;
            border-radius: 50%;
            margin-left: 23px;
            box-shadow: $box-shadow-raised;
            @extend .animation-transition-general;
            img {
                width: 100%;
            }
        }
        a {
            color: $white-color;
            text-decoration: none;
            padding: $padding-base-vertical 15px;
            white-space: nowrap;
            @extend .animation-transition-general;
        }
        .info {
            >a {
                display: block;
                line-height: 18px;
                >span {
                    @extend .animation-transition-general;
                    display: block;
                    position: relative;
                    opacity: 1;
                }
            }
            .caret {
                position: absolute;
                top: 8px;
                right: 18px;
            }
        }
    }
}

.visible-on-sidebar-regular {
    display: inline-block !important;
}

.visible-on-sidebar-mini {
    display: none !important;
}

.off-canvas-sidebar {
    .nav {
        >li>a,
        >li>a:hover {
            color: $white-color;
        }
        >li>a:focus {
            background: rgba(200, 200, 200, 0.2);
        }
    }
}

.main-panel {
    position: relative;
    float: right;
    width: $sidebar-width;
    background-color: #ebecf1;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    >.content {
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: -30px;
    }
    >.navbar {
        margin-bottom: 0;
    }
    .header {
        margin-bottom: 50px;
    }
}

.perfect-scrollbar-on {
    .sidebar,
    .main-panel {
        height: 100%;
        max-height: 100%;
    }
}

.navbar.fixed-top {
    width: calc(100% - 260px);
    z-index: 10;
    right: 0;
    left: auto;
}

@media (max-width: 991px) {
    .navbar.fixed-top {
        width: 100%;
        [class*="container-"] {
            @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        }
    }
    .nav-open .fixed-top [class*="container-"] {
        @include transform-translate-x(260px);
    }
}

@media (min-width: 991px) {
    .sidebar,
    .main-panel,
    .sidebar-wrapper {
        -webkit-transition-property: top, bottom, width;
        transition-property: top, bottom, width;
        -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
        transition-duration: 0.2s, 0.2s, 0.35s;
        -webkit-transition-timing-function: linear, linear, ease;
        transition-timing-function: linear, linear, ease;
        -webkit-overflow-scrolling: touch;
    }
    .sidebar-mini {
        .visible-on-sidebar-regular {
            display: none !important;
        }
        .visible-on-sidebar-mini {
            display: inline-block !important;
        }
        .navbar.fixed-top {
            width: calc(100% - 80px);
        }
        .navbar-minimize {
            opacity: 0;
        }
        .sidebar,
        .sidebar .sidebar-wrapper {
            width: 80px;
        }
        .main-panel {
            width: $sidebar-mini-width;
        }
        .sidebar {
            display: block;
            z-index: 1030;
            .logo {
                a.logo-normal {
                    opacity: 0;
                    @include transform-translate-x(-25px);
                }
            }
            .sidebar-wrapper {
                >.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .info>a>span,
                >.nav li>a p {
                    @include transform-translate-x(-25px);
                    opacity: 0;
                }
            }
        }
        .sidebar:hover {
            width: 260px;
            .logo {
                a.logo-normal {
                    opacity: 1;
                    @include transform-translate-x(0);
                }
            }
            .navbar-minimize {
                opacity: 1;
            }
            .sidebar-wrapper {
                width: 260px;
                >.nav li>a p,
                >.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
                .user .info>a>span {
                    @include transform-translate-x(0px);
                    opacity: 1;
                }
            }
        }
    }
}

.panel-header {
    height: 260px;
    padding-top: 80px;
    padding-bottom: 45px;
    background: #141e30;
    /* fallback for old browsers */
    background: -webkit-gradient( linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
    background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
    position: relative;
    overflow: hidden;
    .header {
        .title {
            color: $white-color;
        }
        .category {
            max-width: 600px;
            color: $opacity-5;
            margin: 0 auto;
            font-size: 13px;
            a {
                color: $white-color;
            }
        }
    }
}

.panel-header-sm {
    height: 135px;
}

.panel-header-lg {
    height: 380px;
}